/**
 *
 * Custom function
 *
 */

(function ($) {

	/*
	 *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
	 */

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function () {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').click(function (event) {
				event.preventDefault();
			});

			if ($this.find('a').attr('target') == '_blank') {
				$this.click(function () {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.click(function () {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	/*
	 *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
	 */

	function loadAsynchronousImage() {

		$('*[data-loadimg]').each(function () {
			var box = $(this),
				src = box.attr('data-loadimg'),
				alt = '',
				img = new Image();

			if (box.attr('data-alt')) {
				alt = box.attr('data-alt');
			}

			$(img).load(function () {
				box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
				$('img', box).hide().fadeIn();

			}).attr('src', src);
		});

		$('*[data-loadbackground]').each(function () {
			var box = $(this),
				src = box.attr('data-loadbackground');

			box.css({
				'background-image': 'url(' + src + ')',
				'background-repeat': 'no-repeat',
				'background-position': 'top center'
			});

			if (box.has(['data-position'])) {
				box.css({
					'background-position': box.attr('data-position'),
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			} else {
				box.css({
					'background-position': 'top center',
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			}

			if (box.attr('data-repeat') == 'repeat') {
				box.css({
					'background-repeat': 'repeat'
				});
			} else {
				box.css({
					'background-repeat': 'no-repeat'
				});
			}
		});
	}


	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */

	function socialOpen() {
		$('[data-socialurl]').each(function () {
			var $this = $(this),
				url = $this.attr('data-socialurl');

			$this.click(function () {
				window.open(url, '', 'width=500, height=500');
			});

		});
	}

	function trackAdwordsCampaign() {
		//$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
	}


	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */

	function openModal() {
		if ($('#onloadmodal').length) {
			if (!$.cookie("openmodal")) {
				$('#onloadmodal').modal();
				$.cookie("openmodal", 'no', {
					path: '/'
				});
			}
		}
	}



	/*
	 *	Funzione per l'apertura di un iframe all'interno di una finestra modale
	 */

	function iframeModalOpen() {
		$('.videoModal').each(function () {
			// impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
			$('.modalButton').on('click', function (e) {
				var src = $(this).attr('data-src');
				var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
				var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

				var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

				// stampiamo i nostri dati nell'iframe
				$(".videoModal iframe").attr({
					'src': src,
					'height': height,
					'width': width,
					'allowfullscreen': ''
				});
			});

			// se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
			$(this).on('hidden.bs.modal', function () {
				$(this).find('iframe').html("");
				$(this).find('iframe').attr("src", "");
			});
		});
	}

	/*
	 *	Funzione per la formattazione delle tabelle con classe listino
	 *	tramite le classi di bootstrap
	 */

	function responsiveTable() {

		$('.entry-content table').each(function () {
			var $this = $(this);
			$this.addClass('table');
			$this.wrap('<div class="table-responsive"></div>');
		});

		if ($(window).width() <= 767) {

			$('#primary .table-responsive').prepend(
				'<span class="angle-scroll-left">' +
				'<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
				'</span>'
			);

			$('#primary .table-responsive').on('scroll', function (event) {
				var angleScrollLeft = $('.angle-scroll-left');

				if ($(this).scrollLeft() >= 1) {
					angleScrollLeft.addClass('fade-out');
				} else {
					angleScrollLeft.removeClass('fade-out');
				}
			});

			$('#primary').find('.table-responsive').each(function () {
				var table = $(this);
				$('.angle-scroll-left').click(function (event) {
					table.animate({
						scrollLeft: '+=60'
					}, 200);
				});
			});
		}
	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
		$('*[data-location]').each(function () {
			var map = $(this),
				address = map.attr('data-location');
			map.setGMap({
				address: address
			});
		});
	}


	/**
	 * Funzione per l'attivazione del menu responsive
	 */

	function responsiveMenu() {
		$('.main-navigation').find('a[href="#"]').each(function () {
			$(this).click(function (event) {
				event.preventDefault();
			});
		});

		var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

		$('.main-navigation').find('a[href="#"]').each(function () {
			$(this).click(function (event) {
				//$('ul.sub-menu').removeClass('open');
				$(this).toggleClass('opened');
				$(this).next().toggleClass('open');
			});
		});

		$("#js-menu-offcanvas-button-open").click(function () {
			menuPrimaryOffcanvas.addClass("offcanvas-open");
		});

		$("#js-menu-offcanvas-button-close").click(function () {
			menuPrimaryOffcanvas.removeClass("offcanvas-open");
		});
	}


	/**
	 * Funzione per nascondere e visualizzare la testata allo scroll della pagina
	 */

	function scrollHeader() {
		$('#js-header').scrollHide({
			scrollDelta: 10,
			scrollOffset: 110,
			scrollClass: 'scrh-header',
			scrollClassHide: 'scrh-hide',
			onScroll: function (context, msg, currentTop) {
				if (currentTop !== 0) {
					$('#js-header').addClass('scrolled');
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

				if (currentTop === 0) {
					$('#js-header').removeClass('scrolled');
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

			}
		});
	}


	/**
	 * Funzione per gestire il fake form allo scroll della pagina
	 */

	function scrollFake() {
		var formFake = $('#request'),
			button = $('#button-side');

		if ($(window).width() > 1199 && formFake.length > 0) { // se esiste il form e non siamo a livello mobile (smartphones)

			if (!formFake.parents('#secondary').length) { // se il form non è racchiuso lateralmente (aside con id secondary)

				var position = formFake.offset().top + formFake.outerHeight();

				$(window).scroll(function () {
					if ($(this).scrollTop() >= position) {
						if (lang == 'en') {
							button.css('right', -260);
						} else {
							button.css('right', -300);
						}
					} else {
						button.css('right', -400);
					}
				});

			}

		}
	}


	/*
	 *  Funzioni per PhotoSwiper
	 */
	function addImageSizeForPhotoSwiper() {
		var thumbs = $('img[data-big-size]');
		thumbs.each(function (index) {
			if ($(this).parent('a').length > 0) {
				var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
				if (isImageLink) {
					big_size = $(this).data('big-size');
					link = $(this).parent('a');
					link.attr('data-size', big_size);
					link.wrap('<div class="single-image"></div>');
				}
			}
		});
	}

	function initPhotoSwiper(selector) {
		var galleryItems = $(selector);
		var pswpItems = [];
		galleryItems.each(function (index) {
			var dimensions = $(this).find('a').data('size').split('x');
			var width = parseInt(dimensions[0], 10);
			var height = parseInt(dimensions[1], 10);

			myImage = {
				src: $(this).find('a').attr('href'),
				w: width,
				h: height,
				msrc: $(this).find('img').attr('src')
			};

			pswpItems.push(myImage);
		});


		galleryItems.each(function (index) {
			$(this).click(function (evt) {
				evt.preventDefault();
				openPhotoSwipe(pswpItems, index);
			});
		});
	}

	function openPhotoSwipe(galleryItems, index) {
		var options = {
			index: index
		};
		var pswpElement = $('.pswp')[0];

		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
		gallery.init();
	}


	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {

					if (target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					}
					else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}

					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);

					return false;
				}
			}
		});
	}

	function startOwl(selector) {
		$(selector).each(function (arguments) {
			var owl = $(this);
			var params = {
				nav: owl.data('owl-navigation'),
				navSpeed: owl.data('owl-slide-speed'),
				autoplay: owl.data('owl-autoplay'),
				autoplayTimeout: owl.data('owl-autoplay-timeout'),
				autoplaySpeed: owl.data('owl-autoplay-speed'),
				dots: owl.data('owl-dots'),
				dotsSpeed: owl.data('owl-dots-speed'),
				loop: owl.data('owl-loop'),
				center: owl.data('owl-center'),
				autoWidth: owl.data('owl-auto-width'),
				autoHeight: false,
				lazyLoad: true,
				rewind: owl.data('owl-rewind'),
				animateOut: owl.data('owl-animation-out'),
				animateIn: owl.data('owl-animation-in'),
				navText: [
					'<svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82"><g fill="none" fill-rule="evenodd"><polygon fill="rgb(255,255,255)" fill-rule="nonzero" points="51.711 18.788 50.577 17.807 30.796 40.653 50.577 63.499 51.711 62.517 32.78 40.653"/></g></svg>',
					'<svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82"><g fill="none" fill-rule="evenodd"><polygon fill="rgb(255,255,255)" fill-rule="nonzero" points="30.796 18.788 31.93 17.807 51.711 40.653 31.93 63.499 30.796 62.517 49.727 40.653"/></g></svg>'
				]
			};

			if (owl.data('owl-nav-prev') && owl.data('owl-nav-next')) {
				params['navText'] = [
					owl.data('owl-nav-prev'),
					owl.data('owl-nav-next')
				];
			}

			if (owl.data('owl-single-item')) {
				params['items'] = 1;
			} else {
				params['responsive'] = {
					0: {
						items: owl.data('owl-items-xs'),
					},
					768: {
						items: owl.data('owl-items-sm'),
					},
					992: {
						items: owl.data('owl-items-md'),
					},
					1200: {
						items: owl.data('owl-items-lg'),
					},
					1440: {
						items: owl.data('owl-items-xl'),
					}
				};
			}

			owl.owlCarousel(params);

			if (owl.data('owl-prev')) {
				$(owl.data('owl-prev')).click(function () {
					owl.trigger('prev.owl.carousel');
				});
			}

			if (owl.data('owl-next')) {
				$(owl.data('owl-next')).click(function () {
					owl.trigger('next.owl.carousel');
				});
			}
		});
	}

	function slideMobile() {
		if (!jQuery('body').hasClass('is-mobile')) return;

		var imgItem = jQuery('.header-slideshow .item img');

		if (jQuery(window).width() > 767) {

			for (i = 0; i < imgItem.length; i++) {
				jQuery(imgItem[i]).attr('src', mobileSize.tablet[i])
			}
		} else {
			for (i = 0; i < imgItem.length; i++) {
				jQuery(imgItem[i]).attr('src', mobileSize.mobile[i])
			}
		}
	}

	/**
	 * SOLO PER FORM VERTICAL BOOKING
	 * Funzione per nascondere/visualizzare l'età dei bambini
	 */

	function selectBambiniVertical() {

		$("*[data-bambini]").each(function () {
			var $this = $(this),
				bambiniID = $this.attr("data-bambini");


			$(bambiniID).find("[name*='st1bamb']").hide();

			$this.change(function () {

				var i = 1;

				while (i <= $(bambiniID).find("[name*='st1bamb']").length) {

					if (i <= $("option:selected", $this).val()) {
						$(bambiniID).find("[name='st1bamb" + i + "']").show();
					} else {
						$(bambiniID).find("[name='st1bamb" + i + "']").hide();
					}

					i++;

				}

			});

			$this.trigger("change");

		});
	}

	/**
	 * SOLO PER FORM MMONE
	 * Funzione per nascondere/visualizzare camere e l'età dei bambini
	 */

	function selectBambiniMmone() {
		/* età bambini */
		$('.efp-booking-children').change(function () {
			var room = $(this).data('room');
			var maxRooms = $('[name="maxRooms"').val();
			var maxChildren = $('[name="maxChildren"').val();
			$('.efp-booking-children-age').hide();
			$('.label-eta').hide();
			for (c = 1; c <= maxRooms; c++) {
				if ($('[name="bform[reqRooms][' + c + '][child]"').val() > 0) $('.label-eta').show();
				for (b = 1; b <= maxRooms; b++) {
					if (b <= $('[name="bform[reqRooms][' + c + '][child]"').val()) $('[name="bform[reqRooms][' + c + '][childAge][' + b + ']"').show();
				}
			}
		});

		/* numero camere */
		$('.num-rooms').change(function () {
			$('.multiroom').hide();
			var n = $(this).val();
			if (n > 1) {
				for (i = 2; i <= n; i++) $('#room' + i).show();
			}
		});
	}

	function selectBambiniIper() {
		/* età bambini */
		$('.efp-booking-children').change(function () {
			var room = $(this).data('room');
			var maxRooms = $('[name="maxRooms"').val();
			var maxChildren = $('[name="maxChildren"').val();
			$('.efp-booking-children-age').hide();
			$('.label-eta').hide();
			for (c = 1; c <= maxRooms; c++) {
				if ($('[name="Camera_' + c + '_Bambini"').val() > 0) $('.label-eta').show();
				for (b = 1; b <= maxRooms; b++) {
					if (b <= $('[name="Camera_' + c + '_Bambini"').val()) $('[name="Camera_' + c + '_EtaBambino_' + b + '"').show();
				}
			}
		});

		/* numero camere */
		$('.num-rooms').change(function () {
			$('.multiroom').hide();
			var n = $(this).val();
			if (n > 1) {
				for (i = 2; i <= n; i++) $('#room' + i).show();
			}
		});
	}

	function selectBegenius() {

		/* Età bambini */

		$('.efp-booking-children').change(function () {
			var numChildren = $(this).val();

			$('.efp-booking-children-age').hide();
			$('label.child-age').hide();
			if (numChildren == 0) $('.efp-booking-children-age').prop('selectedIndex', 0);

			var i;
			for (i = 1; i <= numChildren; i++) {
				$('[name="eta_bambini[' + i + ']"]').show();
				$('label[for="child' + i + '"]').show();
			}
		});
	}

	/*****************************************************************************************/

	function toConsole(string) {
		if ((typeof window.console == "undefined")) {
			alert(string);
		} else console.log(string);
	}

	function $j(argument) {
		var result = jQuery(argument);
		if (result.length === 0) return null;
		else return result;
	}

	function translateCountdown(langCountdown) {

		countdown.resetLabels();

		if (langCountdown == 'it') {
			countdown.setLabels(
				' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
				' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
				' e ',
				' '
			);
		} else if (langCountdown == 'de') {
			countdown.setLabels(
				' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
				' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
				' und ',
				' '
			);
		} else if (langCountdown == 'fr') {
			countdown.setLabels(
				' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
				' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
				' et ',
				' '
			);
		}

	}

	function edtCountdown() {

		jQuery(".edt_countdown_class").find('.item').each(function () {

			var dataInizio = "";
			var questo = jQuery(this);
			jQuery.post(
				url.ajax_url,
				{
					'action': 'edita_ajax_get_start_countdown_date',
					'offerId': questo.data('offerid'),
				},

				function (response) {
					setInterval(function () {
						stampaCountdown(questo, response);
					}, 3600);
				}
			);
		});
	}

	function stampaCountdown(item, response) {

		jQuery(item).find('.pageTimer').empty().append(
			moment().countdown(response, countdown.DAYS | countdown.HOURS).toString()
		);
	}

	function closePhotoswipe() {

		$('.pswp__button--close').click(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});

		$(window).scroll(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	}

	/*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {

		var filterSelect = '.select-offers',
			container = '.mix-filter-support';

		if ($(container).length) {
			var mixer = mixitup(container);

			$(filterSelect).on('change', function () {
				mixer.filter(this.value);
			});
		}
	}

	/**
	 * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
	 * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
	 * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
	 * ritorna un boleano.
	 */
	var mapsMobileUrl = {
		isAndroid: function () {
			return /(android)/i.test(navigator.userAgent);
		},
		isIos: function () {
			return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
		}
	};


	function injectorLinkMaps() {
		var buttonMaps = $('.dove-siamo-button').find('.navigator');

		if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', phpMapsMobileUrl.android);
		if (mapsMobileUrl.isIos()) buttonMaps.attr('href', phpMapsMobileUrl.ios);
		if (!mapsMobileUrl.isAndroid() && !mapsMobileUrl.isIos()) buttonMaps.hide();
	}


	// mobile cta

	/*
		function mobileCta() {
			
			if( $('#mobile-header-cta').length > 0 ) {
				
				var $cta = $('#mobile-header-cta');
				
				var windowWidth = $(window).width();
				var headerHeight = $('#js-header').height() + $('.header-slideshow').height();
				var windowHeight = $(window).height();
				var offset = 240;
				
				if( windowWidth < 768 ) { offset = 180; }
				
				var buttonPosition = (windowHeight - headerHeight) + offset;
				
				$cta.css('bottom', buttonPosition + 'px');
				
				// if page is scrolled on load
				var initialWindowPos = $( window ).scrollTop();
				
				if( initialWindowPos > 100 ) {
					
					$cta.css('bottom', 0 + 'px');
				}
				
				$( window ).scroll(function() {
					
					if( $(this).scrollTop() > 100 ) {
						
						$cta.css('bottom', 0 + 'px');
						
					} else {
					
						$cta.css('bottom', buttonPosition + 'px');
					}
				})
			}
		}
	*/



	$j(document).ready(function () {

		allClick();
		responsiveTable();
		addImageSizeForPhotoSwiper();
		iframeModalOpen();
		responsiveMenu();
		scrollHeader();
		filterElements();
		injectorLinkMaps();

		//selectBambiniVertical();
		//selectBambiniMmone();
		//selectBambiniIper();
		//selectBegenius();

		$('.feedback-description').collapser({
			mode: 'chars',
			truncate: 360,
			showText: '[ + ]',
			hideText: '[ - ]',
			controlBtn: 'opener',
		});

		startOwl('.owl-carousel');
		smoothScroll();
		slideMobile();

		closePhotoswipe();

		if (typeof lang && lang != 'en') {
			translateCountdown(lang);
		}

		edtCountdown();

		// Fix for Apple devices

		var ua = navigator.userAgent,
			iOS = /iPad|iPhone|iPod/.test(ua),
			iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

		// ios 11 bug caret position
		if (iOS && iOS11) {

			// Add CSS class to body
			$("body").addClass("iosBugFixCaret");
		}

		/* Date Picker */

		if ($.datepicker) {
			$.datepicker.setDefaults($.datepicker.regional[lang]);
			jQuery('#arrival, #departure').datepicker({
				beforeShow: function (input) {
					var minDate = 1;
					if (input.id == "departure") {
						minDate = jQuery('#arrival').datepicker("getDate");
						minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
					}

					return {
						minDate: minDate
					}
				}
			});
		}


		/* Cambio messaggio di errore */

		$('#richiesta-preventivo').find('input[required]').on('change invalid', function () {
			var field = $(this).get(0);
			var message = $(this).attr('title');

			field.setCustomValidity('');

			if (!field.validity.valid) {
				field.setCustomValidity(message);
			}
		});

		$('#newsletter').find('input[required]').on('change invalid', function () {
			var field = $(this).get(0);
			var message = $(this).attr('title');

			field.setCustomValidity('');

			if (!field.validity.valid) {
				field.setCustomValidity(message);
			}
		});

		/* Validazione form per browser non HTML5 */

		$('#richiesta-preventivo').submit(function (e) {

			var form = $(this);

			// Integrazione sysdat

			var start = jQuery('#arrivo').datepicker("getDate");
			var end = jQuery('#partenza').datepicker("getDate");
			var diff = end - start;
			var days = diff / 1000 / 60 / 60 / 24;

			$(':input[name="n_notti"]', form).val(days);

			// Gestione formattazione date

			var arrivo = $('#arrivo').val(),
				partenza = $('#partenza').val(),
				separatore = "/";

			var arrDateArrivo = arrivo.split(separatore);
			$('input[name="gg_a"]', form).val(arrDateArrivo[0]);
			$('input[name="mm_a"]', form).val(arrDateArrivo[1]);
			$('input[name="aa_a"]', form).val(arrDateArrivo[2]);

			var arrDatePartenza = partenza.split(separatore);
			$('input[name="gg_p"]', form).val(arrDatePartenza[0]);
			$('input[name="mm_p"]', form).val(arrDatePartenza[1]);
			$('input[name="aa_p"]', form).val(arrDatePartenza[2]);

			// Verifica campi richiesti

			var check = true;
			var element = form.find("[required]");
			var regex = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

			$('*').removeClass('error');

			$(element).each(function () {
				if ($(this).val() === "") {
					$(this).addClass("error");
					check = false;
				}

				if ($(this).prop('type') === "email" && !regex.test($(this).val())) {
					$(this).addClass("error");
					check = false;
				}

				if ($(this).prop('type') === "checkbox" && !$(this).prop("checked")) {
					$(this).addClass("error");
					check = false;
				}
			});

			if (check) {
				return true;
			} else {
				return false;
			}

		});

		$('#newsletter').submit(function (e) {

			var form = $(this);

			// Verifica campi richiesti

			var element = form.find("[required]");
			var regex = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

			$('*').removeClass('error');

			$(element).each(function () {
				if ($(this).prop('type') === "email" && !regex.test($(this).val())) {
					$(this).addClass("error");
					$(this).focus();
					e.preventDefault();
					return false;
				}

				if ($(this).prop('type') === "checkbox" && !$(this).prop("checked")) {
					$(this).addClass("error");
					$(this).focus();
					e.preventDefault();
					return false;
				}
			});

			//----- Fix per form in https -----
			var action = form.attr('action');
			if (action.indexOf("piumail.com") >= 0 || action.indexOf("editawebmarketing.com") >= 0 || action.indexOf("inemail.it") >= 0) {
				form.attr('action', action.replace("https://", "http://"));
			}
			//---------------------------------

			return true;

		});

		scrollFake();

		$('.gform_wrapper form').each(function () {
			$(this).checkEditaForm();
		});

		$('.checkNewsletter').each(function () {
			$(this).checkEditaForm();
		});

		/* Fix per modale con contenuto dinamico */
		$('#feedModal').on('hidden.bs.modal', function (e) {
			$(this).removeData('bs.modal');
			$(".modal-content", this).empty();
		});

	});


	$j(window).load(function () {

		//mobileCta();

		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		if ($('body').hasClass('page-template-template-mappa')) {

			$.getScript('https://www.google.com/jsapi', function () {
				if (url.key) {
					var map_params = 'language=' + url.lingua + '&key=' + url.key;
				}
				else {
					var map_params = 'language=' + url.lingua;
				}

				google.load('maps', '3', {
					other_params: map_params + '&libraries=places',
					callback: function () {
						initializeGmap();
					}
				});
			});
		}

		//$('#page').css( 'margin-top', $('#js-header').outerHeight() );

		var gallery = $('.gallery');

		if (gallery.length) {
			gallery.each(function (index) {
				initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
			});
		}

		initPhotoSwiper(".entry-content .single-image");


		//note(@duncanmid): site menu

		$('#toggle-menu').click(function () {

			if ($(this).hasClass('menu-opened')) {

				$(this).removeClass('menu-opened');
				$('html').removeClass('no-scroll');
				$('#js-header, #site-menu').removeClass('menu-opened');

			} else {

				$(this).addClass('menu-opened');
				$('html').addClass('no-scroll');
				$('#js-header, #site-menu').addClass('menu-opened');
			}
		});


		//note(@duncanmid): feedback pager

		if ($('#feedback').length > 0) {

			$('.feedback-slideshow .owl-dots').appendTo('#ta-nav');
		}


		//note(@duncanmid): faq details / summary accordion

		$('.faq-section summary').click(function (e) {

			e.preventDefault();

			var $this = $(this).parent();
			var attr = $this.attr('open');

			$('.faq-section details').removeAttr('open');

			if (typeof attr !== typeof undefined && attr !== false) {

				$this.removeAttr('open');

			} else {

				$this.attr('open', '');
			}
		});
	});

	$j(window).resize(function () {



	});

})(jQuery);
