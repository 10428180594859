/**
	CheckEditaForm v 2.4

	integrato con Gravity Forms
		gli input obbligatori sono contenuti dentro un elemento .gfield_contains_required
		i check sulle date "linkate" tra loro avvengono passando per l'elemento padre 'li' -> next li -> input dentro next li

	integrato con picker.js (http://amsul.ca/pickadate.js)
		-> AGGIUNGERE la classe .pickadate-container ai contenitori degli input che devono aprire il calendario
		controlla se le date devono essere legate tra loro con link_date
		* per i booking: convertire data in formato dd/mm/aaaa con convert_date_to_numbers a true *

	possibile nascondere i label, aggiungendo la classe (default: .hidelabel)
	all'intero form o ai singoli contenitori degli input

	tracciamento Google Analytics (UNIVERSAL)
		<input type="hidden" name="track-form-event" class="track-form-event" value="form-contatti">

	split dei campi data in giorno, mese, anno per poter inviare i singoli valori (es: vertical). I campi data dovranno avere la seguente struttura:
		<input type="text" class="date" data-date-ref="book-arrivo" placeholder="arrivo">
		<input type="text" class="date" data-date-ref="book-partenza" placeholder="partenza">
	inoltre dovranno essere creati i relativi campi hidden per ogni input.date con il relativo attributo -giorno, -mese, -anno:
		<input name="gg" value="" type="hidden" data-ref="book-arrivo-giorno">
		<input name="mm" value="" type="hidden" data-ref="book-arrivo-mese">
		<input name="aa" value="" type="hidden" data-ref="book-arrivo-anno">

	Aggiunto controllo hasCustomSelect per validazione su input personalizzati

	integrata la gestione del concatenamento di campi input (merge_fields_source) in un campo hidden di destinazione (merge_field_destination)
	concatenati da un carattere di separazione (merge_separator) usata per il booking Ericsoft

	Aggiunta doppia validazione email.
	Inserire 2 campi di tipo email chiamati email e email2 e i relativi attributi data con collegamenti invertiti
	Esempio:
	<input type="email" name="email" id="email" value="" class="max" data-link-to="email2">
	<input type="email" name="email2" id="email2" value="" class="max" data-link-from="email">

*/


(function(jQuery){
jQuery.fn.checkEditaForm = function(settings) {


	// Valori di Default per il plugin
	settings = jQuery.extend({
		bgerror: '#f4e19c',                                     // background di elementi non validi,
		colorerror: '#000',                                         // colore del testo di elementi non validi
		hide_label_class: '.hidelabel',                         // per nascondere il label
		date_selector: '.pickadate-container input[type="text"]',            // classe per i campi con calendario,
		link_date: true,                                        // aggancio le date per bloccare la data minima e massima
		date_format: false,                                     // di default (false) il formato è dd/mm/aaaa, a true si visualizzerà la data in parole con il giorno della settimana
		convert_date_to_numbers: false,                         // converto le date in formato numerico (per i booking)
		submitselector: '.gform_button',                        // selettore dell'elemento che innesca la validazione
		booking_splitdate: false,                               // prende le date e le divide in gg, mm, aaaa per inviare i dati separatamente (es: vertical booking)
		merge_fields_source: '',                                // campi da unire (es: field1|field2|field3)
		merge_field_destination: '',                            // campo separatore
		merge_separator: ''                                     // campo di destinazione da valorizzare con la stringa generata
	},settings); 

	// Variabili Globali
	var form = jQuery(this);
	var error = 0;



	function _start()
	{

		var input_array = jQuery(':input',form).not(".gform_hidden");
		input_array.each(function()
		{
			var the_bg = jQuery(this).css('background-color');
			var parent_bg = jQuery(this).parents('.gfield_contains_required').css('background-color');

			var the_color = jQuery(this).css('color');
			var parent_color = jQuery(this).parents('.gfield_contains_required').css('color');

			jQuery(this).attr('data-original-bg',the_bg);
			jQuery(this).parents('.gfield_contains_required').attr('data-original-bg',parent_bg);

			jQuery(this).attr('data-original-color',the_color);
			jQuery(this).parents('.gfield_contains_required').attr('data-original-color',parent_color);
		});

		// inserisco i placeholder nei campi
		setInputPlaceHolders();

		// inizializzo il calendario sui campi con data
		initDate();

		jQuery(settings.submitselector, form).bind('click', sendForm);
	}


	function setInputPlaceHolders()
	{

		// non passo checkbox, radio, select, bottone di submit e input hidden e le select
		var input_toparse = jQuery(':input',form);

		input_toparse.each(function()
		{
			var the_input = jQuery(this);

			if(the_input.parents(settings.hide_label_class).length > 0)
			{
				//var the_label = the_input.parent().siblings('.gfield_label');
				var the_label = jQuery('.gfield_label',the_input.parents('.gfield'));
				if(!the_input.is('select') && !the_input.is('[type="checkbox"]')) the_input.attr('placeholder',the_label.text());


				if(jQuery('.input_trackingform').length > 0)
				{
					jQuery('.input_trackingform').prev().hide();
				}
				the_label.hide();
			}
		});

	}

	function initDate()
	{
		var site_lang = jQuery('html').attr('lang');
		site_lang = site_lang.substr(0,2);

		// aggancio il calendario
		jQuery(settings.date_selector, form).each(function()
		{
			var single_input = jQuery(this);
			
			var myCalendarContainer;
			single_input.attr('readonly', 'readonly');

			// if(single_input.data('abs-container')){
			//     myCalendarContainer = single_input.data('abs-container');
			// } else {
			//     myCalendarContainer = 'body';
			// }

			//se settato a false imposta la data nel formato classico 
			single_input.datepicker();

			// se le date del form sono collegate
			if(settings.link_date)
			{
				// sul change del valore controllo se esiste un'input data immediatamente dopo
				single_input.bind('change', function()
				{
					//var the_real_date = single_input.siblings('input[type="hidden"]').val();
					var next_input = single_input.parents('.gfield').next('.pickadate-container').find('input');

					// se esiste blocco la data minima a quella selezionata precedentemente
					if(next_input.length > 0)
					{
						//var this_picker = single_input.pickadate('picker');
						//var next_picker = next_input.pickadate('picker');
						//var js_date_format = this_picker.get('select');
						var this_picker_value = single_input.datepicker('getDate');
						next_input.datepicker('setDate', this_picker_value);
					}
				});
			}

			//attivazione campo booking vertical

			var date_ref = single_input.attr('data-date-ref'),
				dateseparator = "/";

			single_input.change(function(event,date)
			{

				if (settings.booking_splitdate == true)
				{

					var date_string = jQuery(this).siblings('input[name="_submit"]').val();
					var tmparray = date_string.split(dateseparator);

					jQuery('input[data-ref="'+ date_ref + '-giorno"]',form).val(tmparray[0]);
					jQuery('input[data-ref="'+ date_ref + '-mese"]',form).val(tmparray[1]);
					jQuery('input[data-ref="'+ date_ref + '-anno"]',form).val(tmparray[2]);
				}

			});
		});
	}

	function initValidation()
	{
		error = 0;
		var required_fields_container = jQuery('.gfield_contains_required',form);

		required_fields_container.each(function()
		{
			var input_to_check = jQuery('.ginput_container :input',this).not('button').not(':hidden');

			// se devo validare un solo input
			if(input_to_check.length == 1)
			{
				var type= input_to_check.attr('type');

				switch (type)
				{
				case 'text':
					validateAsText(input_to_check);
				break;

				case 'email':
					validateAsEmail(input_to_check);
				break;

				case 'checkbox':
					validateAsCheckbox(input_to_check);
				break;

				default:
					validateAsText(input_to_check);
				break;
				}

			}
			// se devo validare un gruppo di input (almeno uno valido -> checkbox o radio)
			else if (input_to_check.length > 1)
			{
				var type= jQuery(input_to_check[0]).attr('type');
				var at_least = false;

				input_to_check.each(function()
				{
					var $this = jQuery(this);

					switch (type)
					{
					case 'checkbox':
						if(validateAsCheckbox($this,1)) at_least= true;
					break;
					case 'radio':
						//validazione radio
					break;
					default:
						validateAsCheckbox($this,1);
					break;
					}

				});

				// se nessuno dei campi è valido
				if(!at_least)
				{
					highlightError(input_to_check.parent());
					error++;
				}
				else resetError(input_to_check.parent());
			}

		});

	}

	// Validazione dei campi txt
	function validateAsText(this_input,nohighlight)
	{



		if(this_input.val() == '' || this_input.val() == this_input.attr('placeholder'))
		{
			if(nohighlight!=1)
			{
				error ++;

				if(this_input.hasClass("hasCustomSelect")){
					highlightError(this_input.parent().find("span.customSelect"));
				}else{
					highlightError(this_input);
				}

			}
			return false;
		}
		else
		{
			 if(nohighlight!=1) {
				if(this_input.hasClass("hasCustomSelect")){
					resetError(this_input.parent().find("span.customSelect"));
				}else{
					resetError(this_input);
				}
			}

			return true;
		}
	}

	// Validazione dei campi email
	function validateAsEmail(this_input)
	{

		//var emailRegexp = RegExp('^[A-Za-z0-9._-]+[@]([A-Za-z0-9-]+[.])+([A-za-z]{2,4})$', 'i');

		var emailRegexp = RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

		//Controllo l'uguaglianza tra i 2 campi email
		if(this_input.data('link-to') || this_input.data('link-from')){

			if(this_input.data('link-to')){
				var dataLink = this_input.data('link-to');
				var this_input2 = jQuery("input[name="+dataLink+"]",form);
				var mail1 = this_input.val();
				var mail2 = this_input2.val();
				if(mail1 != mail2 || mail1 == ''){
					error ++;
					highlightError(this_input);
					highlightError(this_input2);
				}else{
					if (this_input.val() == this_input.attr('placeholder') || this_input2.val() == this_input2.attr('placeholder') || !emailRegexp.test(this_input.val()) || !emailRegexp.test(this_input2.val()) ){
						error ++;
						highlightError(this_input);
						highlightError(this_input2);
					}else{
						resetError(this_input);
						resetError(this_input2);
					}
				}
			}
		}else{
			if (this_input.val() == this_input.attr('placeholder') || !emailRegexp.test(this_input.val())  )
			{
				error ++;
				highlightError(this_input);
			}else{
				resetError(this_input);
			}

		}

	}

	// Validazione dei campi checkbox
	function validateAsCheckbox(this_input,nohighlight)
	{
		if(this_input.is(':checked'))
		{
			if(nohighlight!=1) resetError(this_input.parents('.gfield_contains_required'));
			return true;
		}
		else
		{

			if(nohighlight!=1)
			{
				highlightError(this_input.parents('.gfield_contains_required'));
				error ++;
			}
			return false;
		}
	}


	// Animazione sui campi in errore
	function highlightError(this_input)
	{
		this_input.css({'background-color': settings.bgerror,'position':'relative'});
		if(settings.colorerror != '') this_input.css({'color': settings.colorerror});

		this_input.animate({left: '-10'},120).animate({left: '+10'},120).animate({left: '-10'},120).animate({left: '0'},120, function() {
			// Animation complete.
			if(jQuery(this).hasClass("customSelect")){
				jQuery(this).css("position", "");
				//jQuery(this).css("background-image", "/wp-content/themes/edita/images/icons.png");
			}

		});
	}

	// Reset del campo con value valido
	function resetError(this_input)
	{

		this_input.css('background-color',this_input.attr('data-original-bg'));
		this_input.css('color',this_input.attr('data-original-color'));
	}

	function convertDataToNumberFormat()
	{
		jQuery('.picker__input',form).each(function()
		{
			var input_to_convert = jQuery(this);
			var input_to_read = input_to_convert.siblings('input[type="hidden"]');

			input_to_convert.val(input_to_read.val());
		});
	}

	function sendForm()
	{
		initValidation();

		if (settings.booking_splitdate == true && jQuery('input[type="hidden"][data-ref="book-numero-notti"]', form).length != 0)
		{
			var arrivo = 0,
				partenza = 1;

			jQuery(settings.date_selector,form).each(function()
			{

				var data_ref_value = jQuery(this).attr('data-date-ref'),
					date_string = jQuery(this).siblings('input[name="_submit"]').val();

				if(data_ref_value.indexOf('arrivo') != -1) arrivo = date_string.replace(/\//g, "");
				if(data_ref_value.indexOf('partenza') != -1) partenza = date_string.replace(/\//g, "");


			});

			jQuery('input[type="hidden"][data-ref="book-numero-notti"]', form).val(partenza-arrivo);
		}


		//Concatenazione dei campi
		if (settings.merge_fields_source != '')
		{
			var field_string = '';
			var str_separatore = settings.merge_separator;

			//Se non passo un carattere separatore uso _
			if (settings.merge_separator == ''){
				str_separatore = "_";
			}
			var tmparray = settings.merge_fields_source.split("|");

			for(var a=0; a < tmparray.length; a++){
				var tmpval = jQuery("[name='"+tmparray[a]+"']").val();

				//Setto i campi non valorizzati a 0
				if(tmpval == ''){
					tmpval = 0;
				}
				field_string += tmpval;
				if(a < (tmparray.length-1)){
					field_string += str_separatore;
				}
			}
			//Setto il valore del campo di destinazione
			jQuery("[name='"+settings.merge_field_destination+"']").val(field_string);
		}

		if(error == 0)
		{

			//Disabilito il bottone di invio per evitare invii multipli
			jQuery(settings.submitselector, form).prop("disabled",true);

			if(settings.convert_date_to_numbers)
			{
				convertDataToNumberFormat();
			}

			var form_title = jQuery(':input[name="track-form-event"]',form).val();
			/*
			* tracciamento Universal Analytics (funziona con input hidden nei commenti in testata)
			*/
			if(form_title) 
			{
				try
				{
					ga('send', 'event', 'form', 'submit', form_title);
				} catch(e){}
				try
				{
					ga('newTracker.send', 'event', 'form', 'submit', form_title);
				} catch(e){}
			}

			//----- Fix per form in https -----
			var action = form.attr('action');
			if (action.indexOf("piumail.com") >= 0 || action.indexOf("editawebmarketing.com") >= 0 || action.indexOf("inemail.it") >= 0) {
				form.attr('action', action.replace("https://", "http://"));
			}
			//---------------------------------

			form.submit();
		}
		else return false;
	}



	return _start();
};
})(jQuery);
